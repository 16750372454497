<script>
import eventBus from '@/utils/eventBus'

export default {
  props: {
    linkage: {
      type: Object,
      default: () => {
      },
    },
    element: {
      type: Object,
      default: () => {
      },
    },
  },
  created() {
    if (this.linkage?.data?.length) {
      eventBus.$on('v-click', this.onClick)
      eventBus.$on('v-hover', this.onHover)
      eventBus.$on('v-leave', this.onLeave)
      eventBus.$on('v-dataChange', this.onDataChange)
      eventBus.$on('v-dataRecover', this.onDataRecover)
      eventBus.$on('v-currentData', this.onCurrentData)
    }
  },
  mounted() {
    const {data, duration} = this.linkage || {}
    if (data?.length) {
      this.$el.style.transition = `all ${duration}s`
    }
  },
  beforeDestroy() {
    if (this.linkage?.data?.length) {
      eventBus.$off('v-click', this.onClick)
      eventBus.$off('v-hover', this.onHover)
      eventBus.$off('v-leave', this.onLeave)
      eventBus.$off('v-dataChange', this.onDataChange)
      eventBus.$off('v-dataRecover', this.onDataRecover)
      eventBus.$off('v-currentData', this.onCurrentData)
    }
  },
  methods: {
    changeStyle(data = []) {
      data.forEach(item => {
        item.style.forEach(e => {
          if (e.key) {
            this.element.style[e.key] = e.value
          }
        })
      })
    },

    onClick(componentId) {
      const data = this.linkage.data.filter(item => item.id === componentId && item.event === 'v-click')
      this.changeStyle(data)
    },

    onHover(componentId) {
      const data = this.linkage.data.filter(item => item.id === componentId && item.event === 'v-hover')
      this.changeStyle(data)
    },

    onLeave(componentId) {
      const data = this.linkage.data.filter(item => item.id === componentId && item.event === 'v-leave')
      this.changeStyle(data)
    },

    onDataChange(componentId) {
      const data = this.linkage.data.filter(item => item.id === componentId && item.event === 'v-dataChange')
      this.changeStyle(data)
    },

    onDataRecover(componentId) {
      const data = this.linkage.data.filter(item => item.id === componentId && item.event === 'v-dataRecover')
      this.changeStyle(data)
    },

    onCurrentData(componentId,sceneSystemData) {
      if(this.element.id===componentId){
        this.element.propValue=sceneSystemData
      }
    },
  },
}
</script>
